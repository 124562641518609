import { apiDashboardV1ConsoleLogOutGet } from '@/api';
import { Phone1 } from '@bedrock/icons-react';
import { Avatar, Popover, Modal, Button } from '@/components';
import { RootState } from '@/models/store';
import I18N from '@/utils/I18N';
import { Dropdown } from '@bedrock/components';
import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { history } from 'umi';

import VerifyDownloadGuide from '../verifyDownloadGuide';

import styles from './index.less';

const InfoPanel = () => {
	const { info, name } = useSelector((state: RootState) => state.user);
	const { firstName = '', username, lastName = '' } = info;

	const [verifyGuideStep, setVerifyGuideStep] = useState<'1' | '2'>('1');

	const [visible, setVisible] = useState(false);
	const [visibleGuide, setVisibleGuid] = useState(false);

	const enterTimer = useRef<number>();
	const leaveTimer = useRef<number>();

	const smallEnterTimer = useRef<number>();
	const smallLeaveTimer = useRef<number>();

	const avatarLetter = useMemo(() => {
		return name.slice(0, 1);
	}, [firstName, lastName]);

	const logout = () => {
		Modal.warning({
			title: I18N.auto.logOut,
			content: I18N.auto.afterAccountLogout,
			status: 'error',
			icon: false,
			okText: I18N.auto.logOut2,
			cancelText: I18N.auto.cancel,
			width: 518,
			onOk: () => {
				apiDashboardV1ConsoleLogOutGet().then((res) => {
					location.href = res;
				});
			},
		});
	};

	const handleEnter = (e: React.SyntheticEvent, isBig = true) => {
		if (isBig) {
			clearTimeout(enterTimer.current);
			clearTimeout(leaveTimer.current);
			e.persist();
			enterTimer.current = window.setTimeout(() => {
				setVisible(true);
			}, 100);
		} else {
			clearTimeout(smallEnterTimer.current);
			clearTimeout(smallLeaveTimer.current);
			clearTimeout(enterTimer.current);
			clearTimeout(leaveTimer.current);
			e.persist();
			smallEnterTimer.current = window.setTimeout(() => {
				setVisibleGuid(true);
			}, 100);
		}
	};

	const handleLeave = (e: React.SyntheticEvent, isBig = true) => {
		if (isBig) {
			clearTimeout(enterTimer.current);
			clearTimeout(leaveTimer.current);
			e.persist();
			leaveTimer.current = window.setTimeout(() => {
				setVisible(false);
			}, 100);
		} else {
			clearTimeout(smallEnterTimer.current);
			clearTimeout(smallLeaveTimer.current);
			e.persist();
			smallLeaveTimer.current = window.setTimeout(() => {
				setVisibleGuid(false);
			}, 100);
		}
	};

	const overlayContent = (
		<div className={styles['info-container']} onMouseLeave={handleLeave} onMouseEnter={handleEnter}>
			<div className={classNames('flex flex-col', styles.info)}>
				<Avatar
					className={classNames('flex items-center justify-center')}
					title={avatarLetter}
					size="medium"
				/>
				<span className={styles.name}>{name}</span>
				<span className={styles.email}>{username}</span>
			</div>
			<div className={styles.btns}>
				{!info.isInnerUser && info.downLoadUrl && (
					<>
						<Popover
							placement="leftTop"
							content={
								<VerifyDownloadGuide
									url={info.downLoadUrl}
									onOkClick={() => setVisibleGuid(false)}
									onMouseEnter={(e) => handleEnter(e, false)}
									onMouseLeave={(e) => handleLeave(e, false)}
								/>
							}
							destroyTooltipOnHide
							showArrow={false}
							align={{ offset: [6, 0] }}
							visible={visibleGuide}
						>
							<div
								className={classNames(styles.item, styles['with-icon'])}
								onMouseEnter={(e) => handleEnter(e, false)}
								onMouseLeave={(e) => handleLeave(e, false)}
							>
								{I18N.auto.downloadVerify}
								<Phone1 style={{ fontSize: 20, color: '#5f687a' }} />
							</div>
						</Popover>
						<div className={styles['seprator-line']} />
					</>
				)}
				<div className={styles.item} onClick={() => history.push(`/config/info${location.search}`)}>
					{I18N.auto.accountInformation}
				</div>
				<div className={styles.item} onClick={logout}>
					{I18N.auto.logOut}
				</div>
			</div>
		</div>
	);

	return (
		<Dropdown
			arrow={false}
			placement="bottomRight"
			title={
				<div onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
					<Avatar className={styles['user-avatar']} title={avatarLetter} size="small" />
				</div>
			}
			className={styles.infoDropdown}
			overlay={overlayContent}
			visible={visible}
		></Dropdown>
	);
};
export default InfoPanel;
