import { apiDashboardV1ConsoleLanguageSettingPost } from '@/api';
import { Dropdown, Message, Modal } from '@/components';
import I18N, { getCurrentLang, LangEnum, setLang } from '@/utils/I18N';
import { removeQueryLocale } from '@/utils/urlLocale';
import { Globe } from '@bedrock/icons-react';
import { useMemo } from 'react';
import { trackBussinessEvent } from '@/utils/countly';
import { CountlyChangeLang } from '@/utils/trackKeys';
import './index.less';

export default function LocaleSetting() {
	let curLocale = getCurrentLang();

	const localeList = useMemo(() => {
		const changeLang = (lang: string | number, name: string) => {
			console.log(lang, 'lang');
			if (curLocale === lang) {
				return;
			}
			Modal.confirm({
				title: I18N.auto.modifyCommonPhrases,
				content: (
					<div className={'set-lang-modal-content'}>
						<strong>
							{I18N.auto.confirmToModifyAs}
							<span>{name}</span> ？
						</strong>
						<p>{I18N.auto.currentAfterModification}</p>
					</div>
				),
				width: 518,
				okText: I18N.auto.modify,
				cancelText: I18N.auto.cancel,
				onOk: () => {
					trackBussinessEvent({
						event: CountlyChangeLang,
						custom: {
							lang,
							app: 'dashboard',
						},
					});
					Message.loading(I18N.auto.languageSwitching);
					apiDashboardV1ConsoleLanguageSettingPost({ language: lang as string }).then(() => {
						setLang(lang as LangEnum);
						removeQueryLocale();
						window.location.reload();
					});
				},
			});
		};
		return [
			{
				label: I18N.common.chinese,
				onClick: (lang: string | number) => changeLang(lang, I18N.common.chinese),
				key: LangEnum['zh-CN'],
			},
			{
				label: I18N.common.english,
				onClick: (lang: string | number) => changeLang(lang, I18N.common.english),
				key: LangEnum['en-US'],
			},
			{
				label: I18N.common.japanese,
				onClick: (lang: string | number) => changeLang(lang, I18N.common.japanese),
				key: LangEnum['ja-JP'],
			},
			{
				label: I18N.common.french,
				onClick: (lang: string | number) => changeLang(lang, I18N.common.french),
				key: LangEnum['fr-CA'],
			},
		];
	}, []);

	return (
		<Dropdown
			options={localeList}
			selectMenu={curLocale}
			placement="bottomRight"
			placements={{
				bottomRight: {
					points: ['tr', 'br'],
					offset: [0, 0],
				},
			}}
		>
			<Dropdown.Button type="text" icon={<Globe size={20}></Globe>}>
				{localeList.find((item) => item.key === curLocale)!.label}
			</Dropdown.Button>
		</Dropdown>
	);
}
