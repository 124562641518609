import { Input as BasicInput } from '@bedrock/components';
import { InputProps } from '@bedrock/components/es/Input/Input';
import './index.less';

interface Props extends InputProps {}

const Input = (props: Props) => {
	const { onBlur: onBlurProps, ...rest } = props;
	const onBlur = (e: any) => {
		// iOS 会在唤起键盘以后把页面内容往上定，收起键盘时页面不会自动复位,需要手动复位
		const ua = window.navigator.userAgent.toLocaleLowerCase();
		const isIOS = /iphone|ipad|ipod/.test(ua);
		if (isIOS) {
			document.documentElement.scrollTop = 0;
		}
		onBlurProps && onBlurProps(e);
	};
	return <BasicInput {...rest} onBlur={onBlur}></BasicInput>;
};

Input.Password = BasicInput.Password;
Input.Search = BasicInput.Search;

export default Input;
