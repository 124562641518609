import { LangEnum } from './I18N';
export let hasQueryLocale = false;

export const getQueryLocale = () => {
	let urlSearchParam = new URLSearchParams(location.search);
	let locale = urlSearchParam.get('locale');
	if (Object.keys(LangEnum).includes(locale as string)) {
		hasQueryLocale = true;
		return LangEnum[locale as LangEnum];
	}
	return '';
};

export const removeQueryLocale = () => {
	const currentURL = new URL(window.location.href);

	currentURL.searchParams.delete('locale');

	const newURL = currentURL.toString();
	history.pushState(null, '', newURL);
};
