export default {
	accountMgr: 'Gestion de compte',
	goBind: 'Lier',
	clickToBind: 'Cliquer sur Ajouter un appareil pour réaliser une liaison à Oscar Verify',
	bindUseVerify: "Lier un compte à votre appareil équippé d'Oscar Verify",
	bindAccount: 'Lier un compte',
	finishDownload: "Télécharger l'appli pour effectuer la liaison",
	scanCode: 'Scanner et télécharger',
	useVerify:
		'Pour garantir la sécurité lors votre connexion, veuillez vous identifier à multiples facteurs avec Oscar Verify',
	downloadVerify: 'Télécharger Oscar Verify',
	pageNotFound: "Cette page n'existe pas.",
	webVersion: 'Web',
	client: 'Client',
	application: "Famille d'applications",
	applicationIntroduction: "Profil de l'application",
	logInToTheApp: "Se connecter à l'application",
	applicationDetails: "À propos de l'application",
	notBoundYet2: 'Aucune',
	bindEmailAs: 'Adresse e-mail ajoutée avec succès',
	removeEmailAs: 'Adresse e-mail supprimée avec succès',
	removeEmailAccount: "Supprimer l'adresse e-mail",
	afterRemovalYouWill_2:
		"Une fois l'adresse mail supprimée, vous ne recevrez plus d'e-mails de vérification Oscar à cette adresse.",
	resetEmailAccount: "Configurer l'adresse e-mail",
	afterResetOs:
		"Après la réinitialisation, les e-mails de vérification d'Oscar seront envoyés à la nouvelle adresse e-mail.",
	authenticationEmail: 'Adresse e-mail',
	actionDisabled: 'Action désactivée',
	confirmToModifyAs1: '',
	currentAfterModification1: '',
	cancel: 'Annuler',
	optional: '(Facultatif)',
	inCaseOfAnyDifficulty:
		"Pour obtenir de l'aide supplémentaire, veuillez rechercher IT Helpdesk dans POPO / Teams / Slack.",
	logOut: 'Se déconnecter ',
	accountInformation: 'Détails du compte',
	logOut2: 'Se déconnecter',
	afterAccountLogout:
		"Une fois déconnecté(e), il ne sera plus possible d'utiliser Dashboard pour vous connecter rapidement à l'application.",
	languageSwitching: 'Changement de langue en cours',
	modify: 'Modifier',
	currentAfterModification:
		'Après le changement, les contenus de votre application, tous vos mails, SMS et les notifications seront paramétrés dans cette langue.',
	confirmToModifyAs: 'Passer à ',
	modifyCommonPhrases: 'Langues',
	ifYourTube:
		'Si votre administrateur ajoute une nouvelle application pour vous, vous verrez la notification ici.',
	noNotice: 'Aucune notification',
	systemNotification: 'Notifications système',
	pleaseContactYour:
		'Veuillez contacter votre administrateur pour ajouter des applications pour vous.',
	noApplication: 'Aucune application',
	myApps: 'Mes applications',
	signIn: 'Se connecter',
	youWillUseO:
		'Connectez-vous à Oscar en utilisant votre compte et votre mot de passe. La prochaine fois que vous cliquerez sur une application, vous serez automatiquement redirigé(e) vers la page de connexion.',
	logInToNam: 'Se connecter à {val1}',
	accounts: 'Compte',
	surname: 'Nom de famille',
	name: 'Prénom',
	ifYouNeedToModifyTheLetter:
		'Pour modifier vos informations personnelles, veuillez contacter votre administrateur.',
	personalInformation: 'Informations personnelles',
	inoperable: "Ce méthode ne peut pas être supprimée lorsqu'il n'y a pas de méthodes alternatives.",
	newEquipment: 'Ajouter un appareil',
	equipmentName: 'Appareil',
	deviceBound: 'Appareils associés : ',
	notBoundYet: 'Aucun',
	cellPhoneNumber: 'Numéro de téléphone',
	toProtectYou:
		"Pour mieux protéger votre compte Oscar, vous devez configurer au moins une méthode d'authentification autre que le mot de passe.",
	password: 'Mot de passe',
	setPasswordHand:
		"Pour une connexion sécurisée à Oscar et à d'autres applications, configurez les méthodes de sécurité telles qu'un mot de passe, un numéro de téléphone mobile et Oscar Verify.",
	securityVerification: 'Authentification sécurisée',
	remove: 'Supprimer',
	afterRemovalYouWill:
		"Après avoir supprimé cet appareil, vous ne pourrez plus recevoir de messages ni de notifications d'Oscar, ni afficher les codes de validation sur Oscar Verify à partir de cet appareil.",
	removeDevice: 'Supprimer',
	reset: 'Réinitialiser',
	afterResetYouNeedTo:
		"Après avoir réinitialisé votre mot de passe, veillez à utiliser le nouveau pour accéder à Oscar Dashboard ainsi qu'à toutes les autres applications autorisées.",
	resetPassword: 'Réinitialiser votre mot de passe',
	osAfterReset:
		'Après avoir modifié votre numéro de cellulaire, le code SMS sera envoyé au nouveau numéro enregistré.',
	resetPhoneNumber: 'Réinitialiser le numéro de cellulaire',
	afterRemovalYouWill2:
		"Une fois que vous avez supprimé cet appareil, vous ne pouvez plus l'utiliser pour recevoir des messages d'Oscar.",
	removeMobileNumber: 'Supprimer un numéro de cellulaire',
	mobileNumberRemoval: 'Numéro de téléphone supprimé avec succès',
	equipmentRemovalInto: 'Appareil supprimé avec succès',
	deviceBoundTo: 'Appareil associé avec succès',
	thePhoneIsSetTo: 'Numéro de cellulaire enregistré',
	passwordResetTo: 'Réinitialisation réussie du mot de passe',
	backToHome: 'Accueil',
	yearD: 'Il y a %d ans.',
	year: 'Il y a un an.',
	dMonth: 'Il y a %d mois.',
	month: 'Il y a un mois.',
	dDays: 'Il y a %s jours',
	day: 'Il y a un jour.',
	dHours: 'Il y a %d heures.',
	hour: 'Il y a une heure.',
	dMinutes: 'Il y a %d minutes',
	minute: 'Il y a une minute.',
	just: 'Il y a quelques instants.',
	numbe: 'Il y a {val1}',
	afterS: 'Après %s',
	networkAnomaly: 'Erreur de réseau',
	return: 'Retour',
	enteringWork: 'Accès à Dashboard en cours…',
	stow: 'Réduire',
	open: 'Plus',
	binding: 'Associer',
	loading: 'Chargement en cours',
	helpCenter: "Centre d'aide",
	callApp: "Ouverture de l'application",
	downloadApp:
		"Vous n'avez pas encore téléchargé l'application ? Vous pouvez la télécharger sur son site officiel en cliquant sur le bouton ci-dessous.",
	downloadAppBtn: 'Télécharger',
	openApp: "Ouvrir l'application",
};
