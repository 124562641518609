import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { history } from 'umi';
import I18N, { getCurrentLang } from '@/utils/I18N';
import Icon, { ChevronUp, PlusCircle } from '@bedrock/icons-react';
import { Button, Icon as IconFont } from '@/components';

import { ReactComponent as MouseIcon } from '@/assets/icon/mouse.svg';

import styles from './index.less';
import classNames from 'classnames';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
	title: string;
	desc: string;
	contentNode: React.ReactNode;
	footer: React.ReactNode;
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	url: string;
	onOkClick?: () => void;
}

export const Container = ({ title, desc, contentNode, footer, ...others }: ContainerProps) => {
	return (
		<div className={styles['container']} {...others}>
			<div className={styles['title']}>{title}</div>
			<div className={styles['desc']}>{desc}</div>
			<div className={styles['content-wrapper']}>{contentNode}</div>
			<div className={styles['bottom-wrapper']}>{footer}</div>
		</div>
	);
};

export const DownloadCodeContent = ({ url }: Props) => {
	const downloadUrl = `${window.location.origin}${url}`;
	return (
		<div className={styles['download-code']}>
			<div className={styles['code-wrapper']}>
				<QRCode
					size={106}
					level="Q"
					value={downloadUrl}
					imageSettings={{
						src: require('@/assets/images/appLogo2.png'),
						width: 24,
						height: 24,
						excavate: false,
					}}
				/>
			</div>
			<div className={styles['title']}>{I18N.auto.scanCode}</div>
		</div>
	);
};

export const BindAccountContent = () => {
	const lang = getCurrentLang();
	return (
		<div className={styles['bind-account']}>
			<div className={styles['verify-info']}>
				<div className={styles['info-top']}>
					<div className="flex items-center">
						<div className={classNames('flex items-center justify-center', styles['xian'])}>
							<IconFont name="icon-logo-xian" />
						</div>
						<div className={styles['info']}>
							<div className={styles['label']}>Oscar Verify</div>
							<div className={styles['data']}>
								{I18N.auto.deviceBound}
								0/6
							</div>
						</div>
					</div>
					<ChevronUp style={{ fontSize: 20 }} />
				</div>
				<div className={styles['info-bottom']}>
					<div className={classNames(styles['bottom-button'], styles[lang])}>
						<PlusCircle className={styles['plus']} />
						<div className={styles['font']}>{I18N.auto.newEquipment}</div>
						<Icon component={MouseIcon} className={styles['arrow']} />
					</div>
				</div>
			</div>
			<div className={styles['title']}>{I18N.auto.clickToBind}</div>
		</div>
	);
};

const VerifyDownloadGuide = ({ url, onOkClick, ...others }: Props) => {
	const [verifyGuideStep, setVerifyGuideStep] = useState<'1' | '2'>('1');
	return (
		<Container
			{...others}
			title={verifyGuideStep === '1' ? 'Oscar Verify' : I18N.auto.bindAccount}
			desc={verifyGuideStep === '1' ? I18N.auto.useVerify : I18N.auto.bindUseVerify}
			contentNode={
				verifyGuideStep === '1' ? <DownloadCodeContent url={url} /> : <BindAccountContent />
			}
			footer={
				verifyGuideStep === '1' ? (
					<Button
						type="primary"
						className={styles['action-button']}
						onClick={() => {
							setVerifyGuideStep('2');
						}}
					>
						{I18N.auto.finishDownload}
					</Button>
				) : (
					<>
						<Button
							type="checked-neutral"
							className={styles['action-button']}
							onClick={() => {
								setVerifyGuideStep('1');
							}}
						>
							{I18N.auto.return}
						</Button>
						<Button
							type="primary"
							className={styles['action-button']}
							onClick={(e) => {
								e.stopPropagation();
								history.push(`/config/verify${location.search}`);
								onOkClick?.();
							}}
						>
							{I18N.auto.goBind}
						</Button>
					</>
				)
			}
		/>
	);
};

export default VerifyDownloadGuide;
