import { Message } from '@/components';
import { OSCAR_DEVICEID, OSCAR_STATE_TOKEN } from '@/const';
import I18N from '@/utils/I18N';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import type { RequestFunctionParams } from 'yapi-to-typescript';
import { RequestBodyType } from 'yapi-to-typescript';
import { scoutTrackError } from '@/utils/scout';
import { ScoutSpectialRequestError, ScoutNotLoginError } from '@/utils/trackKeys';

export interface RequestOptions {
	mock?: boolean;
	//接口错误是否弹窗
	showError?: boolean;
}
const axios = Axios.create({
	// TODO: 测试
	timeout: 60000, //5s超时时间
});

interface ResBaseBody<T> {
	status: number;
	message: string;
	data: T;
}

enum ERROR_STATUS {
	NOT_LOGIN = 305, // 用户未登录
	NOT_FOUND = 404, // 数据不存在
	NOT_ALLOWED = 405, // 没有权限操作
	SERVICE_TEMPORARILY_UNAVAILABLE = 503,
	UNKNOWN_ERROR = 500, // 未知错误
}

class ServerResError extends Error {
	code?: number;
	data?: any;
	constructor(public message: string, code?: number, data?: any) {
		super(message);
		this.code = code;
		this.data = data;
	}
}

export default function request<TResponseData>(
	payload: RequestFunctionParams,
	options: RequestOptions = {
		mock: false,
		showError: false,
	}
): Promise<TResponseData> {
	// 基本地址
	const baseUrl = options.mock ? payload.mockUrl : location.origin;

	// 请求地址
	const url = `${baseUrl}${payload.path}`;

	// 具体请求逻辑
	const axiosOptions: AxiosRequestConfig = {
		url,
		method: payload.method,
		headers: {
			'tenant-key': 'oc',
			'state-token': localStorage.getItem(OSCAR_STATE_TOKEN) || '',
			'device-id': localStorage.getItem(OSCAR_DEVICEID) || '',
		},
		data: payload.hasFileData
			? payload.getFormData()
			: payload.requestBodyType === RequestBodyType.json
			? payload.data
			: payload.requestBodyType === RequestBodyType.form
			? Object.keys(payload.data)
					.filter((key) => payload.data[key] != null)
					.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(payload.data[key])}`)
					.join('&')
			: undefined,
	};
	return axios(axiosOptions).then((res: AxiosResponse<ResBaseBody<TResponseData>>) => {
		const result = res.data;

		if (!result || result.status !== 1) {
			const error = new ServerResError(`* ${result.message}`, result?.status, result.data);
			const globalError = [
				ERROR_STATUS.NOT_ALLOWED,
				ERROR_STATUS.NOT_FOUND,
				ERROR_STATUS.SERVICE_TEMPORARILY_UNAVAILABLE,
				ERROR_STATUS.UNKNOWN_ERROR,
			];
			if (globalError.includes(result.status)) {
				scoutTrackError({
					name: `${ScoutSpectialRequestError}${result.status}`,
					message: error.message || '-',
					stack: error.stack,
				});
				Message.error(error.message);
			} else if (options.showError) {
				Message.error(error.message);
			} else if (result.status === ERROR_STATUS.NOT_LOGIN) {
				scoutTrackError({
					name: ScoutNotLoginError,
					message: error.message || '-',
					stack: error.stack,
				});
				location.href = (result.data as any).redirectUrl;
			}
			throw error;
		}
		return result.data;
	});
}
axios.interceptors.response.use(
	(res) => res,
	(err) => {
		if (err && !err.response) {
			err.message = I18N.auto.networkAnomaly;
		}

		return Promise.reject(err);
	}
);
