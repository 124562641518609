import { Tooltip } from '@/components';
import { getIsMobile } from '@/utils';
import I18N, { getCurrentLang } from '@/utils/I18N';
import { Helpcenter } from '@bedrock/icons-react';
import classNames from 'classnames';
import styles from './index.less';

interface Props {}

const HelpBtn = (props: Props) => {
	// const popoverContent = <div className={styles['help-text']}>{I18N.auto.helpCenter}</div>;
	const isMobile = getIsMobile();
	const locale = getCurrentLang();
	const url = isMobile
		? `${window.location.origin}/help-center/h5?locale=${locale}`
		: `${window.location.origin}/help-center/web?locale=${locale}`;
	return (
		// <Popover
		// 	title=""
		// 	content={popoverContent}
		// 	placement="topRight"
		// 	overlayClassName="help-popover"
		// 	arrowPointAtCenter
		// >
		// 	<div className={classNames('flex items-center justify-center', styles['help-btn'])}>
		// 		<Helpcenter />
		// 	</div>
		// </Popover>
		<Tooltip title={isMobile ? '' : I18N.auto.helpCenter}>
			<div
				className={classNames('flex items-center justify-center', styles['help-btn'])}
				onClick={() => {
					window.open(`${url}${location.search}`);
				}}
			>
				<Helpcenter />
			</div>
		</Tooltip>
	);
};

export default HelpBtn;
