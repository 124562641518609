import {
	apiDashboardV1ConsoleLanguageSettingPost,
	apiDashboardV1ConsoleQueryLoginUserInfoGet,
	ApiDashboardV1ConsoleQueryLoginUserInfoGetResponse,
} from '@/api';
// import { CHANGE_LANG } from '@/const';
// import eventBus from '@/utils/eventBus';
import { getCurrentLang, LangEnum, setLang } from '@/utils/I18N';
import { hasQueryLocale } from '@/utils/urlLocale';
import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { scoutLogin } from '@/utils/scout';

export const user = createModel<RootModel>()({
	state: {
		info: {} as ApiDashboardV1ConsoleQueryLoginUserInfoGetResponse,
		name: '',
	}, // initial state
	reducers: {
		setInfo(state, result: ApiDashboardV1ConsoleQueryLoginUserInfoGetResponse) {
			return { ...state, info: result };
		},
		setName(state, name: string) {
			return { ...state, name };
		},
	},
	effects: (dispatch) => ({
		async getInfo() {
			return apiDashboardV1ConsoleQueryLoginUserInfoGet().then((res) => {
				const { firstName = '', lastName = '', languageSetting, setting, username, email } = res;
				let name = '';
				const locale = getCurrentLang();
				if (locale === LangEnum['ja-JP'] || locale === LangEnum['zh-CN']) {
					name = `${lastName} ${firstName}`;
				} else {
					name = `${firstName} ${lastName}`;
				}
				scoutLogin(username || email);
				dispatch.user.setInfo(res);
				dispatch.user.setName(name);
				// 首次登录，未设置过常用语言时，按当前语言设置常用语言
				if (setting === 0) {
					apiDashboardV1ConsoleLanguageSettingPost({ language: locale as string }).then(() => {
						setLang(locale);
					});
				} else if (languageSetting !== locale && !hasQueryLocale) {
					// 在没有 query locale 的时候，如果当前语言和用户的设置语言不一致 需要刷新
					setLang(languageSetting as LangEnum);
					window.location.reload();
				}
				// if (languageSetting !== locale) {
				// 	// eventBus.emit(CHANGE_LANG, null, locale, languageSetting);
				// }
				return res;
			});
		},
	}),
});
