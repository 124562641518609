export default {
	accountMgr: '外包/供应商账号管理',
	goBind: '前往绑定',
	clickToBind: '点击新增设备绑定Oscar Verify',
	bindUseVerify: '将已安装Oscar Verify的设备和账号绑定',
	bindAccount: '绑定账号',
	finishDownload: '完成下载，绑定账号',
	scanCode: '扫码下载',
	useVerify: '使用Oscar Verify 进多因素身份认证，保障登录安全',
	downloadVerify: '下载 Oscar Verify',
	pageNotFound: '页面不存在',
	webVersion: '网页版',
	client: '客户端',
	application: '所属应用',
	applicationIntroduction: '应用简介',
	logInToTheApp: '登录应用',
	applicationDetails: '应用详情',
	notBoundYet2: '暂未绑定',
	bindEmailAs: '邮箱绑定成功',
	removeEmailAs: '邮箱移除成功',
	removeEmailAccount: '移除邮箱账号',
	afterRemovalYouWill_2: '移除后，您将无法再用此邮箱账号接收 Oscar 系统发送的验证邮件',
	resetEmailAccount: '重置邮箱账号',
	afterResetOs: '重置后，Oscar 系统发送的验证邮件将发送给新邮箱',
	authenticationEmail: '认证邮箱',
	actionDisabled: '不可操作',
	confirmToModifyAs1: '',
	currentAfterModification1: '',
	cancel: '取消',
	optional: '(选填)',
	inCaseOfAnyDifficulty: '如遇任何困难，您可通过 POPO / Teams / Slack 搜索 IT Helpdesk 进行反馈。',
	logOut: '退出登录',
	accountInformation: '帐号信息',
	logOut2: '登出',
	afterAccountLogout: '帐号登出后，您将无法再使用工作台快速登录应用。',
	languageSwitching: '语言切换中...',
	modify: '切换',
	currentAfterModification:
		'切换后，当前的工作台语言以及您未来可能收到的 Oscar\r\n系统发出的邮件、短信、消息通知都将变更为该语言。',
	confirmToModifyAs: '确认切换为',
	modifyCommonPhrases: '切换语言',
	ifYourTube: '如果您的管理员为您添加了新应用，您将在这里收到通知',
	noNotice: '暂无通知',
	systemNotification: '系统通知',
	pleaseContactYour: '请联系您的管理员为您添加',
	noApplication: '暂无应用',
	myApps: '我的应用',
	signIn: '登录',
	youWillUseO: '您将使用 Oscar 帐号和密码登录，下次点击任一应用，将自动跳转登录',
	logInToNam: '登录 {val1}',
	accounts: '帐号',
	surname: '姓氏',
	name: '名字',
	ifYouNeedToModifyTheLetter: '如需修改信息，请联系 Oscar 管理员。',
	personalInformation: '个人信息',
	inoperable: '暂不可移除，添加其他验证方式后可移除',
	newEquipment: '新增设备',
	equipmentName: '设备名称',
	deviceBound: '已绑定设备：',
	notBoundYet: '暂未绑定',
	cellPhoneNumber: '手机号',
	toProtectYou: '为了保护您的 Oscar 帐号安全，请至少再设置一种除密码外的安全验证方式。',
	password: '密码',
	setPasswordHand:
		'设置密码、手机、Oscar Verify 等安全验证方式，有助于您安全便捷地登录 Oscar 和其他应用程序。',
	securityVerification: '安全验证',
	remove: '移除',
	afterRemovalYouWill:
		'移除后，您将无法再用此设备接收 Oscar 系统发送的消息通知，也无法在该设备的 Oscar Verify 上查看验证码。',
	removeDevice: '移除设备',
	reset: '重置',
	afterResetYouNeedTo: '重置后，需使用新密码进行 Oscar 工作台以及被分配应用的登录。',
	resetPassword: '重置密码',
	osAfterReset: '重置后，Oscar 系统发送的验证短信将发往新手机，',
	resetPhoneNumber: '重置手机号',
	afterRemovalYouWill2: '移除后，您将无法再用此手机号接收 Oscar 系统发送的验证短信。',
	removeMobileNumber: '移除手机号',
	mobileNumberRemoval: '手机号移除成功',
	equipmentRemovalInto: '设备移除成功',
	deviceBoundTo: '设备绑定成功',
	thePhoneIsSetTo: '手机设置成功',
	passwordResetTo: '密码重置成功',
	backToHome: '返回首页',
	yearD: '%d年',
	year: '1年',
	dMonth: '%d月',
	month: '1月',
	dDays: '%d天',
	day: '1天',
	dHours: '%d小时',
	hour: '1小时',
	dMinutes: '%d分钟',
	minute: '1分钟',
	just: '刚刚',
	numbe: '{val1}前',
	afterS: '%s后',
	networkAnomaly: '网络异常',
	return: '返回',
	enteringWork: '正在进入工作台...',
	stow: '收起',
	open: '展开',
	binding: '绑定',
	loading: '加载中...',
	helpCenter: '帮助中心',
	callApp: '唤起应用中',
	downloadApp: '尚未安装应用？请点击下方按钮前往官网下载',
	downloadAppBtn: '前往下载',
	openApp: '打开应用',
};
