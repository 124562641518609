import { store } from '@/models/store';
import '@/utils/performance';
import '@bedrock/components/base.css';
import { ReactNode } from 'react';
import { Provider } from 'react-redux';

export const rootContainer = (container: ReactNode) => (
	<Provider store={store}>{container}</Provider>
);

// export const render = (oldRender: any): void => {
// 	if (process.env.NODE_ENV === 'development') {
// 		API.hub.login.testLogin
// 			.request({ uid: 'zhouqing01@corp.netease.com' })
// 			.then(oldRender)
// 			.catch(oldRender);
// 	} else {
// 		oldRender();
// 	}
// };
