export default {
	accountMgr: 'Account Management',
	goBind: 'Set up',
	clickToBind: 'Click to add device and set up Oscar Verify',
	bindUseVerify: 'Add Oscar-Verify-installed devices to your account',
	bindAccount: 'SET UP ACCOUNT',
	finishDownload: 'Downloaded, set up now',
	scanCode: 'Scan to Download',
	useVerify: 'Secure your login with MFA via Oscar Verify',
	downloadVerify: 'Download Oscar Verify',
	pageNotFound: 'Page not found',
	webVersion: 'Web',
	client: 'Client',
	application: 'Product family',
	applicationIntroduction: 'App profile',
	logInToTheApp: 'Log in',
	applicationDetails: 'About',
	notBoundYet2: 'None',
	bindEmailAs: 'Email address added',
	removeEmailAs: 'Email address removed',
	removeEmailAccount: 'Remove email address',
	afterRemovalYouWill_2:
		'Once removed, this email address will no longer receive verification emails from Oscar.',
	resetEmailAccount: 'Reset email address',
	afterResetOs: 'Once reset, Oscar will send verification emails to the new email address.',
	authenticationEmail: 'Email address',
	actionDisabled: 'Action disabled',
	confirmToModifyAs1: 'Do you want to change the language to',
	currentAfterModification1:
		'The language you selected during sign-in has been changed. Do you want to set the current language as your preferred language?',
	cancel: 'Cancel',
	optional: '(Optional)',
	inCaseOfAnyDifficulty:
		'To get further assistance with your account, please search IT Helpdesk in POPO / Teams / Slack.',
	logOut: 'Sign out',
	accountInformation: 'Account Details',
	logOut2: 'Sign out',
	afterAccountLogout:
		'After signing out, you can no longer use Dashboard to quickly sign in to the app.',
	languageSwitching: 'Switching language...',
	modify: 'Change',
	currentAfterModification:
		'After the change, your Dashboard display language and all the future emails, text messages, and notifications from Oscar will appear in this language.',
	confirmToModifyAs: 'Change to',
	modifyCommonPhrases: 'Languages',
	ifYourTube: 'If your admin adds any new app for you, you will see the notification here.',
	noNotice: 'No notifications',
	systemNotification: 'System Notifications',
	pleaseContactYour: 'Please contact your administrator to add apps for you',
	noApplication: 'No apps',
	myApps: 'My apps',
	signIn: 'Sign in',
	youWillUseO:
		'Use your Oscar account and password to sign in once, and you will get automatically signed in to this app next time.',
	logInToNam: 'Sign in to {val1}',
	accounts: 'Account',
	surname: 'Surname',
	name: 'First name',
	ifYouNeedToModifyTheLetter: 'To edit your personal info, please contact the Oscar administrator.',
	personalInformation: 'Personal information',
	inoperable: 'This method cannot be removed when there are no alternative methods.',
	newEquipment: 'Add device',
	equipmentName: 'Device',
	deviceBound: 'Added devices: ',
	notBoundYet: 'None',
	cellPhoneNumber: 'Phone number',
	toProtectYou:
		'To secure your Oscar account, please set up at least one verification method besides password.',
	password: 'Password',
	setPasswordHand:
		'Set up security methods, including password, phone, and Oscar Verify, to sign in to Oscar and other apps safely and conveniently.',
	securityVerification: 'Security Authentication',
	remove: 'Remove',
	afterRemovalYouWill:
		'After removing this device, you can no longer use it to receive messages and notifications from Oscar or view verification codes on Oscar Verify on it.',
	removeDevice: 'Remove',
	reset: 'Reset',
	afterResetYouNeedTo:
		'After resetting your password, you need to use the new password to sign in to Oscar Workbench and other athorized apps.',
	resetPassword: 'Reset password',
	osAfterReset:
		'After resetting your phone number, the verification SMS from Oscar will be sent to the new phone number.',
	resetPhoneNumber: 'Reset phone number',
	afterRemovalYouWill2:
		'After removing this device, you can no longer use it to receive messages and notifications from Oscar or view on Oscar Verify on it.',
	removeMobileNumber: 'Remove phone number',
	mobileNumberRemoval: 'Phone number removed',
	equipmentRemovalInto: 'Removed',
	deviceBoundTo: 'Device added',
	thePhoneIsSetTo: 'Phone number set',
	passwordResetTo: 'Password reset',
	backToHome: 'Back to Home',
	yearD: '%d years ago',
	year: '1 year ago',
	dMonth: '%d months ago',
	month: '1 month ago',
	dDays: '%s days ago',
	day: '1 day ago',
	dHours: '%d hours ago',
	hour: '1 hour ago',
	dMinutes: '%d minutes ago',
	minute: '1 minute ago',
	just: 'Just now',
	numbe: '{val1} before',
	afterS: 'After %s',
	networkAnomaly: 'Network error',
	return: 'Back',
	enteringWork: 'Entering Workbench...',
	stow: 'Less',
	open: 'More',
	binding: 'Add',
	loading: 'Loading...',
	helpCenter: 'Help Center',
	callApp: 'Opening the app',
	downloadApp:
		"Haven't got the app? Go to its official site to download it by clicking the button below",
	downloadAppBtn: 'Download',
	openApp: 'Open app',
};
