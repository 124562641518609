import { REM_SIZE } from '@/const';
import { Button, Divider, Dropdown as BasicDropdown } from '@bedrock/components';
import type { ButtonProps } from '@bedrock/components/es/Button';
import { DropdownProps } from '@bedrock/components/es/Dropdown';
import { CaretDown, Check } from '@bedrock/icons-react';
import { createContext, useContext, useMemo, useState } from 'react';
import s from './index.less';

interface Option {
	label: string;
	onClick: (key: string | number, options: Option) => void;
	key: string;
	icon?: React.ReactNode;
}

interface Props extends DropdownProps {
	options: Option[] | Option[][];
	children: React.ReactNode | ((visiable: boolean) => React.ReactNode);
	selectMenu?: string | number;
	minWidth?: number;
}

const context = createContext({ visible: false });

export default function Dropdown(props: Props) {
	const { options, selectMenu, children, minWidth = 160, ...restProps } = props;
	const [visible, setVisible] = useState(false);

	const overlay = useMemo(() => {
		return (
			<div className={s.menu} style={{ minWidth: minWidth ? `${minWidth / REM_SIZE}rem` : '' }}>
				{options.map((item, index) => {
					const renderItem = (option: Option) => (
						<div
							className={`${s.menuItem} ${
								selectMenu !== undefined && selectMenu === option.key ? s.selected : ''
							}`}
							key={option.key ?? index}
							onClick={() => {
								option.onClick(option.key, option);
								setVisible(false);
							}}
						>
							<div>
								{option.icon}
								<span className={`${option.icon ? s.iconText : ''}`}>{option.label}</span>
							</div>
							{selectMenu !== undefined && selectMenu === option.key ? (
								<Check size={20} className=""></Check>
							) : null}
						</div>
					);
					if (Array.isArray(item)) {
						return item.map((option, index) => (
							<>
								{renderItem(option)}
								{index !== item.length && <Divider></Divider>}
							</>
						));
					} else {
						return renderItem(item);
					}
				})}
			</div>
		);
	}, [options, selectMenu]);

	let contextVal = useMemo(() => ({ visible }), [visible]);

	return (
		<context.Provider value={contextVal}>
			<BasicDropdown {...restProps} overlay={overlay} onVisibleChange={setVisible}>
				{typeof children === 'function' ? children(visible) : children}
			</BasicDropdown>
		</context.Provider>
	);
}

export interface BtnProps extends ButtonProps {}
/*主要解决箭头的旋转交互 */
Dropdown.Button = function Btn(props: BtnProps) {
	const { visible } = useContext(context);
	const { className, ...restProps } = props;

	return (
		<Button
			{...restProps}
			className={`${s.dropdownBtn} ${visible ? s.focus : ''} ${className}`}
			suffix={<CaretDown size={16} className={s.arrow}></CaretDown>}
		></Button>
	);
};
