import I18N from '@/utils/I18N';
import { Form as BasicForm } from '@bedrock/components';
import { FormItemProps, FormProps } from '@bedrock/components/es/Form';
import { PropsWithChildren } from 'react';
import s from './index.less';

interface Props extends FormProps {}

export default function Form(props: Props) {
	let defaultProps: FormProps = {
		labelAlign: 'left',
		labelClassName: s.label,
		layout: 'vertical',
		className: s.form,
	};
	return <BasicForm {...defaultProps} {...props} />;
}

Form.Item = function FormItem(props: PropsWithChildren<FormItemProps>) {
	let { required, label } = props;

	if (!required && label) {
		label = (
			<span>
				{label} <span className={s.optional}>{I18N.auto.optional}</span>
			</span>
		);
	}

	return <BasicForm.Item {...props} label={label}></BasicForm.Item>;
};
// Form.Item = BasicForm.Item;

Form.useForm = BasicForm.useForm;
