import auto from './auto';
import common from './common';

export default Object.assign(
	{},
	{
		common,
		auto,
	}
);
