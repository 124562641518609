import { PerfReport } from '@ultraman/lib';

import skyline from './skyline';

export default new PerfReport({
	shouldReport() {
		return (
			[
				'oc-admin.oscar.netease.com',
				'oc-admin.oscar.easebar.com',
				'oc-admin.staging-oscar.netease.com',
				'oc-admin.staging-oscar.easebar.com',
			].includes(location.host) && Math.random() * 100 < 80
		);
		// return true;
	},
	onReport(event: string, data: Record<string, any>) {
		skyline.send(event, data);
		// console.log(event, data);
	},
});
