import device from '@/utils/device';
import { createModel } from '@rematch/core';
import { RootModel } from '.';

export const app = createModel<RootModel>()({
	state: {
		isMobile: device.isMobile,
		// // 暂时先不兼容移动端
		// isMobile: false,
	}, // initial state
	reducers: {},
	effects: (dispatch) => ({}),
});
