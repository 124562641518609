import { getIsMobile } from '@/utils';
import { Modal as BedrockModal } from '@bedrock/components';
import type { ModalFuncProps, ModalProps } from '@bedrock/components/es/Modal/index';
import s from './index.less';

type Props = ModalProps;

export default function Modal(props: Props) {
	const { wrapClassName, className, ...restProps } = props;
	const isMobile = getIsMobile();

	return (
		<BedrockModal
			maskClosable={false}
			closable={false}
			wrapClassName={`${wrapClassName || ''}`}
			className={`${isMobile ? s.isMobile : s.isPc} ${className || ''}`}
			{...restProps}
		></BedrockModal>
	);
}

Modal.confirm = function (props: ModalFuncProps) {
	const isMobile = getIsMobile();
	return BedrockModal.confirm({
		...props,
		centered: true,
		className: `${isMobile ? s.isMobile : s.isPc} ${s.confirm} `,
	});
};
Modal.warning = function (props: ModalFuncProps) {
	return BedrockModal.warning({ ...props, centered: true, className: s.warning });
};
Modal.info = BedrockModal.info;
Modal.destroyAll = BedrockModal.destroyAll;

// export default BedrockModal;

// BedrockModal.displayName = 'BedrockModal';
