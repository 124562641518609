/**
 * test为测试环境，包含本地开发环境，staging为预发环境，prod为线上环境
 */
type TEnv = 'test' | 'staging' | 'prod';

/**
 * 获取运行环境
 * @returns TEnv
 */
export const getEnv = () => {
	let env: TEnv = process.env.NODE_ENV === 'production' ? 'prod' : 'test';
	switch (location.host) {
		case 'oc.office-oscar.netease.com':
		case 'oc.test-oscar.netease.com':
			env = 'test';
			break;
		case 'oc.staging-oscar.netease.com':
			env = 'staging';
			break;
		case 'oc.stress-oscar.netease.com':
			env = 'prod';
			break;
		default:
			break;
	}
	return env;
};

/**
 * 除了开发环境、测试环境、预发环境、压测环境外，只有线上环境为true
 */
export const isProd = () => {
	return getEnv() === 'prod';
};
