import { getIsMobile } from '@/utils';
import { Message as BasicMessage } from '@bedrock/components';
import { NoticeParam } from '@bedrock/components/es/Message/Message';
import { AlertCircle, CheckCircle, CloseCircle } from '@bedrock/icons-react';
import s from './index.less';

type MessageType = 'success' | 'error' | 'warn' | 'info' | 'loading';

function createToast(type: MessageType) {
	const isMobile = getIsMobile();
	return function toast(config: NoticeParam) {
		let params: NoticeParam;
		if (typeof config === 'string') {
			params = {
				content: config,
				duration: 3,
			};
		} else {
			params = { duration: 3, ...config };
		}
		if (isMobile && type === 'success') {
			params.icon = <CheckCircle />;
		}
		if (isMobile && type === 'warn') {
			params.icon = <AlertCircle />;
		}
		if (isMobile && type === 'error') {
			params.icon = <CloseCircle />;
		}
		return BasicMessage[type](params, {
			maxCount: 1,
			className: isMobile ? s.mobileMessage : s.message,
		});
	};
}

export default {
	info: createToast('info'),
	loading: createToast('loading'),
	success: createToast('success'),
	error: createToast('error'),
	warn: createToast('warn'),
};
