import Skyline from '@popo/skyline-browser';

let skyline = new Skyline({
	serverUrl: 'https://sigma-oscaradmin-dep176.proxima.nie.netease.com',
	authUser: 'dep176',
	localStorageKey: 'oscar-admin-skyline',
	authToken:
		'o04Mkc3Lo0CuY8zpMJc+xOYiC6h8QAl+EmIBYOoazUYC2CTvXgy+8pFBmt2Tr6uIJCMEfewc1FC3xzukaKoHGqePDInKYDKsf2WvZ6/x7Woqn5u9nfkgKRb6dV1enSyM',
});

interface BuryData {
	event: string;
	detail?: Record<string, any>;
	time?: string;
	timestamp?: number;
	project: string;
	host: string;
}

class Agent {
	send(event: string, data?: Record<string, any>) {
		let sendData: BuryData = {
			event,
			project: 'oscar-dashboard',
			host: location.host,
		};
		if (data) {
			sendData.detail = data;
		}
		skyline.send(sendData);
	}
}

export default new Agent();
