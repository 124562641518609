// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import routeProps from './routeProps.js';

if (process.env.NODE_ENV === 'development') {
  Object.entries(routeProps).forEach(([key, value]) => {
    const internalProps = ['path', 'id', 'parentId', 'isLayout', 'isWrapper', 'layout', 'clientLoader'];
    Object.keys(value).forEach((prop) => {
      if (internalProps.includes(prop)) {
        throw new Error(
          `[UmiJS] route '${key}' should not have '${prop}' prop, please remove this property in 'routeProps'.`
        )
      }
    })
  })
}

import React from 'react';

export async function getRoutes() {
  const routes = {"404":{"path":"*","id":"404","parentId":"@@/global-layout"},"app/components/appDetail/description":{"path":"app/components/appDetail/description","id":"app/components/appDetail/description","parentId":"@@/global-layout"},"app/components/appDetail/index":{"path":"app/components/appDetail","id":"app/components/appDetail/index","parentId":"@@/global-layout"},"callApp/$appId":{"path":"callApp/:appId","id":"callApp/$appId","parentId":"@@/global-layout"},"app/index":{"path":"app","id":"app/index","parentId":"@@/global-layout"},"config":{"path":"config","id":"config","parentId":"@@/global-layout"},"config/components/panel/index":{"path":"components/panel","id":"config/components/panel/index","parentId":"config"},"config/verify/index":{"path":"verify","id":"config/verify/index","parentId":"config"},"config/info/index":{"path":"info","id":"config/info/index","parentId":"config"},"index":{"path":"/","id":"index","parentId":"@@/global-layout"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'404': React.lazy(() => import(/* webpackChunkName: "src__pages__404" */'../../../src/pages/404.tsx')),
'app/components/appDetail/description': React.lazy(() => import(/* webpackChunkName: "src__pages__app__components__appDetail__description" */'../../../src/pages/app/components/appDetail/description.tsx')),
'app/components/appDetail/index': React.lazy(() => import(/* webpackChunkName: "src__pages__app__components__appDetail__index" */'../../../src/pages/app/components/appDetail/index.tsx')),
'callApp/$appId': React.lazy(() => import(/* webpackChunkName: "src__pages__callApp__$appId" */'../../../src/pages/callApp/$appId.tsx')),
'app/index': React.lazy(() => import(/* webpackChunkName: "src__pages__app__index" */'../../../src/pages/app/index.tsx')),
'config': React.lazy(() => import(/* webpackChunkName: "src__pages__config" */'../../../src/pages/config.tsx')),
'config/components/panel/index': React.lazy(() => import(/* webpackChunkName: "src__pages__config__components__panel__index" */'../../../src/pages/config/components/panel/index.tsx')),
'config/verify/index': React.lazy(() => import(/* webpackChunkName: "src__pages__config__verify__index" */'../../../src/pages/config/verify/index.tsx')),
'config/info/index': React.lazy(() => import(/* webpackChunkName: "src__pages__config__info__index" */'../../../src/pages/config/info/index.tsx')),
'index': React.lazy(() => import(/* webpackChunkName: "src__pages__index" */'../../../src/pages/index.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/builds/oscar/oscar-frontend/oscar-dashboard-frontend/src/layouts/index.tsx')),
},
  };
}
