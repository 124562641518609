import { isProd } from '@/utils/env';

const APPKEY_DEV = 'e82d3db55487beb777574f9217d23eba14282234';
const APPKEY_PROD = '6617c10e331890e19d459e00147e4e6fdc56f35d';

// countly事件
export interface ICountlyTrackEvent {
	event: string;
	page?: string;
	/** 自定义数据 */
	custom?: Record<string, any>;
}

interface ICountlyParams {
	key: string;
	segmentation: Record<string, any>;
	custom?: Record<string, any>;
}

/**
 * 用户登录后，根据id合并数据统计事件
 */
export const login = (oscarId?: string) => {
	const Countly = window.Countly;
	if (!Countly) {
		return;
	}

	if (!Countly.__isInit) {
		// 还没有初始化，缓存id
		Countly.__cacheDeviceId = oscarId;
		console.warn('update id before countly init');
		return;
	}

	// 重置缓存
	if (oscarId) {
		Countly.__cacheDeviceId = undefined;
	}

	const deviceId = oscarId || Countly.__cacheDeviceId;

	if (!deviceId) {
		return;
	}

	try {
		if (Countly.get_device_id() === oscarId) {
			return;
		}
		if (Countly.get_device_id_type() === Countly.DeviceIdType.DEVELOPER_SUPPLIED) {
			/*change ID without merge as current ID is Dev supplied, so not first login*/
			Countly.q.push(['change_id', oscarId, false]);
		} else {
			/*change ID with merge as current ID is not Dev supplied*/
			Countly.q.push(['change_id', oscarId, true]);
		}
	} catch (error) {
		console.error('countly get device id failed', error);
	}
};

// 初始化countly
const initial = () => {
	// 将 Countly 定义为全局变量
	const Countly: any = {};
	Countly.q = [];

	// 配置 Countly 初始化参数
	Countly.app_key = isProd() ? APPKEY_PROD : APPKEY_DEV;
	Countly.url = 'https://countly.unus.netease.com';

	// 添加需要跟踪的事件
	Countly.q.push(['track_sessions']);
	// Countly.q.push(['track_pageview']);
	// Countly.q.push(['track_clicks']);
	// Countly.q.push(['track_scrolls']);
	// Countly.q.push(['track_errors']);
	// Countly.q.push(['track_links']);
	// Countly.q.push(['track_forms']);
	// Countly.q.push(['collect_from_forms']);

	// 将countly实例挂载到window上，方便后续其他地方使用
	window.Countly = Countly;

	//load countly script asynchronously
	const cly = document.createElement('script');
	cly.type = 'text/javascript';
	cly.async = true;
	cly.src = 'https://countly.unus.netease.com/sdk/web/countly.23.6.0.2.min.js';
	cly.onload = () => {
		try {
			Countly.init();
			Countly.__isInit = true;
			login();
		} catch (error) {
			console.error('countly init failed', error);
		}
	};
	const s = document.getElementsByTagName('script')[0];
	s.parentNode?.insertBefore(cly, s);
};

export const initCountly = () => {
	try {
		console.warn('initCountly');
		initial();
	} catch (error) {
		console.error('init Countly error: \n', error);
	}
};

/**
 * 发送pv事件
 * @param pageName 页面标识
 * @returns void
 */
export const trackPageView = (pageName: string) => {
	const Countly = window.Countly;
	if (!Countly || !pageName) {
		return;
	}

	Countly.q.push(['track_pageview', pageName]);
};

/**
 * 发送业务事件
 * @param e 事件数据
 * @returns void
 */
export const trackBussinessEvent = (e: ICountlyTrackEvent) => {
	const Countly = window.Countly;
	if (!Countly || !e?.event) {
		return;
	}
	try {
		const params: ICountlyParams = {
			key: e.event,
			segmentation: {},
		};
		if (e.custom) {
			params.segmentation = { ...e.custom };
		}
		if (e.page) {
			params.segmentation.page = e.page;
		}
		Countly.add_business_event(params);
	} catch (error) {
		console.error('countly add bu event failed', error);
	}
};
