export default {
	accountMgr: 'アカウント管理',
	goBind: 'デバイスを登録',
	clickToBind: 'クリックしてデバイスを登録',
	bindUseVerify: 'アカウントにOscar Verifyがインストールされているデバイスを登録',
	bindAccount: 'デバイスの登録',
	finishDownload: 'インストール完了後、デバイスを登録',
	scanCode: 'スキャンしてダウンロード',
	useVerify: 'Oscar Verifyを使用して、多要素認証を行う',
	downloadVerify: 'Oscar Verifyをダウンロード',
	pageNotFound: 'このページに到達できません',
	webVersion: 'Web版',
	client: 'クライアント',
	application: '所属ファミリー',
	applicationIntroduction: '説明',
	logInToTheApp: 'ログイン',
	applicationDetails: '詳細情報',
	notBoundYet2: '未登録',
	bindEmailAs: '設定完了',
	removeEmailAs: '削除完了',
	removeEmailAccount: 'メールの削除',
	afterRemovalYouWill_2:
		'削除すると、このメールアカウントで Oscar からの認証メールを受信できなくなります。',
	resetEmailAccount: 'メールの変更',
	afterResetOs: '変更すると、Oscar からの認証メールは変更後のメールアカウントに送信されます。',
	authenticationEmail: '認証用メール',
	actionDisabled: '操作不可',
	confirmToModifyAs1: '次の言語に変更しますか？',
	currentAfterModification1: '言語を変更しました。よく使う言語として設定しますか？',
	cancel: 'キャンセル',
	optional: '（オプション）',
	inCaseOfAnyDifficulty:
		'問題が発生した場合は、POPO / Teams / Slack から IT Helpdeskを検索してフィードバックを送信できます。',
	logOut: 'ログアウト',
	accountInformation: 'アカウント情報',
	logOut2: 'サインアウト',
	afterAccountLogout:
		'アカウントがログアウトされると、作業台を使用してアプリにログインできなくなります。',
	languageSwitching: '言語を変更中…',
	modify: '変更',
	currentAfterModification:
		'変更すると、作業台の言語と、今後 Oscar システムから受け取る電子メール、テキスト メッセージ、及び通知はすべて、この言語に変更されます。',
	confirmToModifyAs: '次の言語に変更します',
	modifyCommonPhrases: 'よく使う言語に変更',
	ifYourTube: '管理者が新しいアプリを追加すると、ここで通知が届きます',
	noNotice: '現在通知なし',
	systemNotification: 'システム通知',
	pleaseContactYour: '管理者に連絡して追加して下さい',
	noApplication: 'アプリなし',
	myApps: 'マイ・アプリ',
	signIn: 'ログイン',
	youWillUseO:
		'Oscarアカウントでログインします。次回からアプリにアクセスするときは、自動的にログインページに移動します。',
	logInToNam: '{val1}にログイン',
	accounts: 'アカウント',
	surname: '苗字',
	name: '名前',
	ifYouNeedToModifyTheLetter: '情報を変更するには、Oscar 管理者に連絡してください。',
	personalInformation: 'プロフィール',
	inoperable: '他の認証方法を追加してから削除してください',
	newEquipment: 'デバイス追加',
	equipmentName: 'デバイス名称',
	deviceBound: '登録済み：',
	notBoundYet: '未登録',
	cellPhoneNumber: '携帯番号',
	toProtectYou:
		'Oscar アカウントのセキュリティを確保するために、パスワード以外の認証方法を少なくとも1つ設定してください。',
	password: 'パスワード',
	setPasswordHand:
		'パスワード、携帯電話、Oscar Verifyなどの認証方法を設定して、Oscar やその他のアプリケーションに安全かつ便利にログインできるようにします。',
	securityVerification: 'セキュリティ認証',
	remove: '削除',
	afterRemovalYouWill:
		'削除すると、このデバイスで Oscar からの通知を受信できなくなり、Oscar Verify で認証コードを表示できなくなります。',
	removeDevice: 'デバイスを削除',
	reset: 'リセット',
	afterResetYouNeedTo:
		'リセットすると、 Oscar 作業台とアプリケーションにログインするには新しいパスワードを使う必要があります。',
	resetPassword: 'パスワード リセット',
	osAfterReset: 'リセットすると、Oscar システムから送信された認証SMSが新しい電話に届きます。',
	resetPhoneNumber: '携帯番号のリセット',
	afterRemovalYouWill2: '削除すると、このデバイスで Oscar からの認証SMSを受信できなくなります。',
	removeMobileNumber: '携帯番号を削除',
	mobileNumberRemoval: '携帯番号 削除成功',
	equipmentRemovalInto: 'デバイス 削除成功',
	deviceBoundTo: 'デバイス 登録成功',
	thePhoneIsSetTo: '携帯番号 設定成功',
	passwordResetTo: 'パスワード リセット成功',
	backToHome: 'ホームページに戻る',
	yearD: '%d年',
	year: '1年',
	dMonth: '%d月',
	month: '1月',
	dDays: '%d日',
	day: '1日前',
	dHours: '%d時間',
	hour: '1時間',
	dMinutes: '%d分',
	minute: '1分',
	just: 'ついさっき',
	numbe: '{val1}前',
	afterS: '%s後',
	networkAnomaly: 'ネットワーク異常',
	return: '戻る',
	enteringWork: '読み込み中…',
	stow: '閉じる',
	open: '展開する',
	binding: '登録',
	loading: '読み込み中…',
	helpCenter: 'ヘルプセンター',
	callApp: 'アプリを開いています',
	downloadApp: '未インストール場合は、下のボタンをクリックしてアプリをダウンロードして下さい',
	downloadAppBtn: 'ダウンロードする',
	openApp: 'アプリを開く',
};
