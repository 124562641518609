//Oscar app在ios和ipad上都是以手机模式运行
let ua = window.navigator.userAgent.toLocaleLowerCase();
let isOscarApp = ua.includes('oscar');
let isMobile =
	new RegExp('iphone|midp|ucweb|android|windows ce|windows mobile', 'i').test(ua) || !!isOscarApp;

// 更全面的检测方法，包括所有版本的 Internet Explorer
export const isIE = /MSIE|Trident/.test(window.navigator.userAgent);

export default {
	isOscarApp,
	isMobile,
	isIpad: ua.includes('ipad'),
	isAndroid: ua.includes('android'),
	isPc: !isMobile,
	//是否有pc浏览器标识，作为判断是否在三方应用的小窗口里。包含国内外多个浏览器
	isInThirdApp:
		!/(firefox|chrome|opr|opera|safari|trident|googlebot|edg|brave|yabrowser|avant|vivaldi)\//.test(
			ua
		),
	// isInThirdApp: true,
};
